.App {
  text-align: center;
  margin-top: 20px;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-wrapper
{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.form-wrapper
{
  max-width: 500px;
  margin: 0 auto;
}

.form-group
{
  margin-bottom: 1rem;
}

.logout-btn{
  right: 0;
  margin-right: 10px;
  top: 5px;
  position: absolute;
  z-index: 1000;
}

.full-width{

  width: 100%;
}

.left-panel
 {
    float: left;
    width: 350px;
    background: white;
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    padding-top: 16px;
 }
 
 .content-panel
 {
    height: 100%;
    
 }

 @media(min-width:1101px){
  .content-panel{
      margin-left: 355px;
      padding: 0px 10px;
  }
  
  .navbar-wrapper
  {
    margin-left: 350px;  
  }
}

body, html {
  height: 100%;
  margin: 0;
}

.new-user-btn{

  width:200px;
  border-bottom: 30px;
}

.btn-right{

  width:200px;
  margin-left: 90%;
}

